import React, { FC } from "react";

import VariantContext from "../../layout/context/variantContext";
import { colors } from "../../utils/colors";
import CustomTooltip from "./custom-tooltip";

const TooltipBadge : FC<{text: string, tooltipText: string, className?: string}> =
    ({ text, tooltipText, className = "p-2 pt-1 pb-1 fs-7 rounded-2 text-body" }) => {

      const { variant } = React.useContext(VariantContext);
      return (
        <CustomTooltip
          text={tooltipText}>
          <span
            style={{ backgroundColor: `${colors[variant.theme as keyof typeof colors].panelBg}95` }}
            className={className}
          >
            {text}
          </span>
        </CustomTooltip>);
    };

export default TooltipBadge;