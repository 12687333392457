import React, { FC, PropsWithChildren, ReactElement } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Placement } from "react-bootstrap/types";

const CustomTooltip : FC<PropsWithChildren<{text: string, tooltipPlacemanet?: string}>> =
    ({ text, tooltipPlacemanet= "top", children }) => {

      const entering = (e: HTMLElement) => {
        (e.children[1] as HTMLElement).style.color = "white";
      };

      const textSplit =text.split("\n");

      const popperConfig = {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 30], // [X-axis, Y-axis] offset
            },
          },
        ],
      };
      const tooltip = () =>
        (
          <Tooltip
            id="tooltip">
            {textSplit.map((textLine, index)=>
              <div key={`tooltip-line-${index}`}>
                <span>{textLine}</span>
                {index <= textSplit.length - 1 && <br/> }
              </div>
            )}
          </Tooltip>
        );

      return (
        <OverlayTrigger
          onEntering={entering}
          overlay={tooltip()}
          popperConfig ={popperConfig}
          placement={tooltipPlacemanet as Placement}>
          {children as ReactElement}
        </OverlayTrigger>);
    };

export default CustomTooltip;