import React, { FC } from "react";

const InteractiveIcon: FC<{className: string, iconName: string, onClick: () => void}>
    = ({ iconName, className, onClick }) => {
      return (
        <span
          onClick={onClick}
          className={`icon ${className} ${iconName}`}></span>
      );
    };

export default InteractiveIcon;