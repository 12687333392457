
export enum Themes {
  Light = "Styl jasny",
  Dark = "Styl granatowy",
  Grey = "Styl szary",
}

export const colors = {
  [Themes.Light]: {
    divider: "#000000",
    lightText: "#BDC0D3",
    primary: "#3e5165",
    panelBg: "#FFFFFF",
    loading: "#3e5165",
    bgOverlay: "#FFFFFF",
    imageBgOverlayOpacity: 0.7,
    imageBgOverlayDetailsOpacity: 0.5,
    secondary:  "#acb7be" },
  [Themes.Dark]: {
    divider: "#FFFFFF",
    lightText: "#BDC0D3",
    primary: "#1d2636",
    panelBg: "#1d2636",
    loading: "#FFFFFF",
    bgOverlay: "#626377",
    imageBgOverlayOpacity: 0.6,
    imageBgOverlayDetailsOpacity: 0.4,
    secondary:  "#374153" },
  [Themes.Grey]: {
    divider: "#FFFFFF",
    lightText: "#d5d5d5",
    primary: "#1c1c1c",
    panelBg: "#1c1c1c",
    loading: "#FFFFFF",
    bgOverlay: "#656565",
    imageBgOverlayOpacity: 0.6,
    imageBgOverlayDetailsOpacity: 0.5,
    secondary:  "#444444" },
};

//old bgoverlay = secondary, opacity 0.7