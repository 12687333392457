import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation } from "react-router-dom";

import { bigScreenBreakpoint, hostAddress, userListsEndpoint } from "../utils/constants";
import { ListType } from "../utils/types";
import { UserCustomListTile } from "./user-custom-list-tile";
import CommonLoading from "./utils/common-loading";
import { GradientLine } from "./utils/gradient-line";

export const UserCustomLists = () => {

  const [listData, setListData ]
      = useState<ListType[]>([]);

  const [listsLoaded, setListsLoaded ]
        = useState(false);

  const { search: includeNotPublished } = useLocation();

  useEffect(() => {
    fetchListsData();
  }, []);

  const prioritized = useMemo(()=>{
    return listData.filter((list)=>
      list?.priority > 0);
  }, [listData]);

  const notPrioritized = useMemo(()=>{
    return listData.filter((list)=>
      !list?.priority);
  }, [listData]);

  const fetchListsData = () => {
    setListData([]);
    fetch(`${hostAddress}${userListsEndpoint}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setListsLoaded(true);
        setListData(includeNotPublished
          ? data
          : data.filter((list : ListType) =>
            list.public));
      });
  };

  return (
    <div className={"d-flex flex-column align-items-start mt-3 container"}>
      <div className={"d-flex w-100 justify-content-start container"}>
        <div>
          <div className={"fs-3"}>Listy tematyczne</div>
          <div>Kupki wstydu RIP</div>
        </div>
      </div>

      <div className={"d-flex w-100"}>
        <GradientLine reverse/>
        <GradientLine/>
      </div>

      {!listsLoaded ?
        <CommonLoading/> :
        <>
          <div
            className={"p-3 d-flex justify-content-start gap-2 flex-wrap w-100"}
          >
            {prioritized.map((list, index) =>
              <UserCustomListTile
                key={`list-prioritized-${index}`}
                list={list}/>
            )}
          </div>
          <div className={"d-flex gap-0 w-100"}>
            <GradientLine reverse/>
            <GradientLine/>
          </div>
          <div
            className={"p-3 d-flex justify-content-start gap-2 flex-wrap"}
          >
            {notPrioritized.map((list, index) =>
              <UserCustomListTile
                key={`list-prioritized-${index}`}
                list={list}/>
            )}
          </div>
        </>}
    </div>
  );
};
