import { FastAverageColor } from "fast-average-color";
import fontColorContrast from "font-color-contrast";
import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  bigScreenBreakpoint, bigScreenDetailsBreakpoint,
  filmCoverEndpoint,
  gameCoverEndpoint, gameCoverRatio,
  hostAddress, userGameCoverWidthSmall,
  userGameDetailsContainerWidth,
  userGameDetailsCoverWidth, userGameMinDetailsDiaryWidth
} from "../utils/constants";
import { UserGameType } from "../utils/types";
import CardBackground from "./card-background";
import { Cover } from "./cover";
import GameOverlay from "./game-overlay";
import ReviewBadge from "./review-badge";
import { UserGameBadges } from "./user-game-badges";
import UserGameHeader from "./user-game-header";
import UserGamePlaythrough from "./user-game-playthrough";
import UserReview from "./user-review";
import InteractiveIcon from "./utils/interactive-icon";

const UserGameDetails : FC<{game: UserGameType, setModalData: (gameData: UserGameType|null) => void}> =
    ({ game, setModalData }) => {
      const [activeReview, setActiveReview] = useState<number|undefined>(game.reviews.length > 0
        ? 0
        : undefined);

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenDetailsBreakpoint}px)` });
      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
      const { variant } = React.useContext(VariantContext);

      const [isLight, setIsLight] =useState<boolean>(false);

      const { platinum, favourite, favouriteMusic, playthroughs, achieved } = game;
      const allPlaythroughs = playthroughs ?? [];

      const assessFontColor = async () => {
        const fac = new FastAverageColor();
        const color = await fac.getColorAsync(`${hostAddress}${gameCoverEndpoint}${game.game.id}`);
        const fontColor = fontColorContrast(color.hex, 0.8);
        setIsLight(fontColor === "#000000");
      };

      useEffect(() => {
        assessFontColor();
      }, []);

      return (
        <Modal
          style={{
            overlay: {
              overflowY: "hidden",
              position: "fixed",
              zIndex: 105,
              backgroundColor: "rgba(0,0,0, 0.9)"
            },
            content: {
              margin: isMobile
                ? 0
                : undefined,
              minHeight: isMobile
                ? "100%"
                : undefined,
              maxHeight: isMobile
                ? "100%"
                : "90%",
              outline: "0",
              borderRadius: isMobile
                ? undefined
                : "6px",
            }
          }}
          onRequestClose={() =>
            setModalData(null)}
          className={`p-0 bg-primary position-absolute overflow-auto 
          ${!isMobile
          ? "scroll-secondary start-50 top-50 translate-middle"
          : "top-50 translate-middle-y hide-scrollbar"}`}
          isOpen={true}
          shouldCloseOnOverlayClick={true}
        >
          <div
            className={`d-flex gap-${isMobile
              ? 2
              : 4} mb-0 ${!isBigScreen && "flex-wrap"} position-relative overflow-hidden`}>
            <CardBackground
              endpoint={gameCoverEndpoint}
              visible={true}
              id={game.game.id}
              zIndex={-10}/>
            <GameOverlay
              opacity={colors[variant.theme as keyof typeof colors].imageBgOverlayDetailsOpacity}
              color={colors[variant.theme as keyof typeof colors].bgOverlay}
              zIndex={-5}
              visible={true}/>

            {isBigScreen ?
              <InteractiveIcon
                className={"m-1 text-danger position-absolute top-0 end-0"}
                iconName={"fas fa-circle-xmark"}
                onClick={() =>
                  setModalData(null)}/> :

              <div
                onClick={() =>
                  setModalData(null)}
                className={"btn btn-danger ms-auto mt-3 me-3 mb-2 d-flex align-items-center rounded-0"}>
                <i
                  className={"me-2 fa-solid fa-arrow-rotate-left fs-6"}
                />
                <span className={"fs-6"}>Powrót</span>
              </div>}


            <div
              className={`d-flex ${!isBigScreen && "p-3 w-100 border-top border-1 border-primary"}`}
              style={{
                minWidth: isBigScreen
                  ? userGameDetailsContainerWidth
                  : undefined,
                width: isBigScreen
                  ? userGameDetailsContainerWidth
                  : undefined
              }}>
              <Cover
                ratio={gameCoverRatio}
                endpoint={gameCoverEndpoint}
                id={game.game.id}
                width={isMobile
                  ? userGameCoverWidthSmall
                  : userGameDetailsCoverWidth}/>
              <UserGameHeader
                isMobile={isMobile}
                lightCover={!isLight}
                stroke
                tags={game.tags}
                hideLastHr
                game={game.game}/>

            </div>
            <div className={"ps-3 pe-3 w-100"}>
              <div
                style={{
                  minWidth: !isMobile
                    ? userGameMinDetailsDiaryWidth
                    : undefined
                }}
                className={`${!isLight
                  ? "text-shadow-black"
                  : "text-black"} mb-2 text-center mt-2`}>
                Dziennik gry
              </div>
              <div>
                {game.playthroughs.map((playthrough) =>
                  <div
                    className={"mt-2 mb-2"}
                    key={`playthrough-${playthrough.id}`}>
                    <UserGamePlaythrough
                      playthrough={playthrough}
                      playthroughCount={game.playthroughs.length}/>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={"d-flex justify-content-between align-items-center p-2 mt-2 mb-2"}>

            <div className={"d-flex"}>
              {game.reviews.map((review, index) =>
                (
                  <ReviewBadge
                    index={index}
                    setActiveReview={setActiveReview}
                    key={review.id}
                    review={review}
                    reviewCount={game.reviews.length}
                    active={activeReview === index}
                    showDate/>
                ))}

              {game.reviews?.length === 0 &&
              <span className={"ms-2 p-2 rounded-2 bg-secondary"}>
                Brak opinii
              </span> }
            </div>
            <div className={"me-2 p-0"}>
              <UserGameBadges
                achieved={achieved}
                favouriteMusic={favouriteMusic}
                favourite={favourite}
                platinum={platinum}
                allPlaythroughs={allPlaythroughs}/>
            </div>
          </div>

          {activeReview !== undefined && (game.reviews[activeReview].entries.length > 0) &&
          <div className={"bg-secondary m-3 mb-3 rounded-2 p-1"}>
            <UserReview review={game.reviews[activeReview]}/>
          </div>
          }
        </Modal>);
    };

export default UserGameDetails;