import { useEffect, useState } from "react";

import {
  gamesEndpoint,
  hostAddress,
} from "../utils/constants";
import { GameType } from "../utils/types";
import LibraryGame from "./library-game";
import CommonLoading from "./utils/common-loading";

const LibraryGameList = () => {
  const [ gameData, setGameData ] = useState<Array<GameType>>([]);

  const fetchGameData = () => {
    fetch(`${hostAddress}${gamesEndpoint}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setGameData(data.games);
      });
  };

  useEffect(() => {
    fetchGameData();
  }, []);

  return (
    <div className={"mt-4"}>
      {gameData.length > 0 ?
        <div
          className='
         d-flex
         flex-wrap 
         justify-content-center'>
          {gameData.map((game) => 
            (
              <LibraryGame
                key={game.id}
                game={game}/>
            ))}
        </div> :
        <div className={"d-flex justify-content-center"}>
          <CommonLoading/>
        </div>
      }
    </div>
  );
};

export default LibraryGameList;