import React, { FC } from "react";

import { colors, Themes } from "../utils/colors";
import { GradientLine } from "./utils/gradient-line";

export const UserSetHeader: FC<{label: string, count: number, theme: Themes,
    toggleSetExtended: (extended: boolean) => void,
    setExtended: boolean}> =
    ({ label,  toggleSetExtended, theme, setExtended, count }) => {
 
      return (

        <div
          className={"d-flex justify-content-center align-items-center mt-3 mb-3"}
          style={{ cursor: "pointer",
            userSelect: "none" }}
          onClick={() => 
            toggleSetExtended(!setExtended)}>
          <GradientLine reverse/>
          <div className={"d-flex align-items-center justify-content-center ps-4 pe-3 gap-2"}>
            <h3
              style={{ color: colors[theme as keyof typeof colors].lightText }}
              className={"text-nowrap mb-0"}>
              {label}
            </h3>
            <span
              className={"badge bg-primary"}>
              {count}
            </span>
            <span
              className={setExtended
                ? "fas fa-caret-up"
                : "fas fa-caret-down"}></span>

          </div>
          <GradientLine/>
        </div>
      );
    };