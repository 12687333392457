import React, { FC, useState } from "react";

import { colors } from "../utils/colors";
import { defaultVariant, userGameDetailsModalWidth } from "../utils/constants";
import { ReviewType } from "../utils/types";
import UserReviewEntry from "./user-review-entry";


const UserReview : FC<{review: ReviewType}>  =
    ({ review }) => {
      const [variant ] = useState(defaultVariant);
      return (
        <div
          className={"p-3 pt-2 pb-2 w-100 d-flex flex-column scroll-secondary"}
          style={{ whiteSpace: "pre-wrap",
            maxWidth: userGameDetailsModalWidth,
            fontSize: 15,
            color: colors[variant.theme as keyof typeof colors].lightText,
            textAlign: "justify" }}>
          {review.entries.map((entry, index) =>
            (
              <UserReviewEntry
                reviewEntry={entry}
                lastEntry={index === (review.entries.length - 1)}
                key={`review-entry-${entry.id}`}/>
            ))}
        </div>);
    };

export default UserReview;