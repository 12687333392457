import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";

import { bigScreenBreakpoint } from "../utils/constants";
import { RoutePaths } from "../utils/routes";
import { ListType } from "../utils/types";


export const UserCustomListTile: FC<{list: ListType}>
    = ({ list }) => {
      const { id, name, description, itemsNumber, date } = list;
      const navigate = useNavigate();
      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

      return (

        <div
          style={{ minHeight: isMobile
            ? 100
            : 200,
          width: 250,
          cursor: "pointer" }}
          className={`rounded-3 custom-list-card gap-2 p-3 bg-primary d-flex ${!isMobile && "flex-column "}
                  justify-content-between ${isMobile && "w-100"}`}
          onClick={() =>
            navigate(`${RoutePaths.LISTS_ROUTE}/${id}`)}>
          <div>
            <div><h3>{name}</h3></div>
            <div><span>{description}</span></div>
          </div>

          <div>
            <div><span>{date}</span></div>
            <div>
              <span>
                {`${itemsNumber} ${itemsNumber > 4
                  ? "wpisów"
                  : "wpisy"}`}
              </span>
            </div>
          </div>
        </div>
          
      );
    };
