import React, { FC } from "react";

import { WatchthroughType } from "../utils/types";
import CustomTooltip from "./utils/custom-tooltip";

export const UserFilmBadges : FC<{favourite: boolean, childhood: boolean, favouriteMusic: boolean,
    allWatchthroughs: WatchthroughType[] }> =
    ({    childhood, favourite, allWatchthroughs, favouriteMusic }) => {

      const completed = allWatchthroughs.filter((watchthrough)=>
        watchthrough.completed).length > 0;

      return (
        <div className={"d-flex"}>
          <div className={"d-flex align-items-center gap-2"}>
            {favouriteMusic && <CustomTooltip text={"ulubiona muzyka"}>
              <span
                style={{ color: "#a4a5ff",
                  marginRight: 3 }}
                className={"fas fa-music"}></span>
            </CustomTooltip>}
            {favourite && <CustomTooltip text={"ulubiona"}>
              <span className={"fas fa-heart text-danger"}></span>
            </CustomTooltip>}
            {childhood && <CustomTooltip text={"gra dzieciństwa"}>
              <span
                style={{ color: "#63ff99" }}
                className={"fas fa-baby-carriage"}></span>
            </CustomTooltip>}
            {completed &&  <CustomTooltip text={"ukończona"}>
              <span className={"fas fa-circle-check"}></span>
            </CustomTooltip>}
          </div>
        </div>
      );
    };
