import { createContext } from "react";

import { Themes } from "../../utils/colors";
import { defaultVariant, Modes, Sort } from "../../utils/constants";

export type VariantType = {
    advancedBgs: boolean,
    randScores: boolean,
    theme: Themes,
    divided: boolean,
    mode: Modes,
    sort: Sort,
    labeled: boolean
}

export type VariantContextType = {
    variant: VariantType;
    setVariant: (e: VariantType) => void;
};

const VariantContext
    = createContext<VariantContextType>({ variant: defaultVariant,
      setVariant: () => 
        null });
export default VariantContext;