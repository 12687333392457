import React, { FC } from "react";

import { hostAddress } from "../utils/constants";

export const Cover : FC<{endpoint: string, id: number, width: number, ratio: number}>
    = ({ id, ratio, endpoint, width }) => {
      return (
      /*    <img
      className={"game-cover bg-black"}
      style={{
        minWidth: width,
        width: width,
        height: width * coverRatio,
        backgroundColor: primaryColor[variant.theme],
        objectFit: "contain",
        objectPosition: "center" }}
      alt={`${id}-cover`}
      src={
        `${hostAddress}${gameCoverEndpoint}${id}`}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.style.objectFit = "fill";
        currentTarget.src= require("../assets/no-cover-black.png");
      }}/>*/

        <div
          className={""}
          style={{
            backgroundSize: "contain",
            backgroundColor: "black",
            width: width,
            minWidth: width,
            height: width * ratio,
            backgroundImage: `url(${hostAddress}${endpoint}${id})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}/>
      );
    };