import React, { useEffect, useMemo, useState } from "react";
import Modal from "react-modal";

import useDetailsHistory from "../hooks/use-details-history";
import VariantContext from "../layout/context/variantContext";
import {
  hostAddress, userFilmsEndpoint,
} from "../utils/constants";
import {
  DetailsModalType,
  UserFilmSetEntryType,
  UserFilmSetType,
  UserFilmType,
} from "../utils/types";
import { FilterPanel } from "./filter-panel";
import { UserFilmDetails } from "./user-film-details";
import { UserFilmSet } from "./user-film-set";
import CommonLoading from "./utils/common-loading";

export const UserFilmList = () => {
  const [filmData, setFilmData ]
      = useState<Array<UserFilmSetType>>([]);

  const [filter, setFilter]
      = useState<string>("");

  const [short, setShort]
      = useState<boolean>(true);

  const [reverseSort, serReverseSort]
      = useState<boolean>(false);

  const [modalData, setModalData]
      = useState<DetailsModalType|null>(null);

  const [coverAttack, setCoverAttack]
      = useState<boolean>(false);

  const [random, setRandom]
      = useState<number|undefined>(undefined);

  const { variant } = React.useContext(VariantContext);
  const { mode, labeled, sort } = variant;

  useDetailsHistory(modalData, setModalData);

  const unprocessedFlat : UserFilmSetEntryType[] = useMemo(()=>
    filmData.reduce((accumulator: UserFilmSetEntryType[], set)=>
      [...set.entries, ...accumulator],  [] ),  [filmData] );

  const processedGameData = useMemo(() => {
    let processedData = filmData.map((set : UserFilmSetType)=>
      ({ label: set.label,
        entries: set.entries.filter((entry: UserFilmSetEntryType)=>
          (entry.filmData.filterValue.includes(filter.toLowerCase()) && (short
            ? true
            : !(entry.filmData.film.isShort) ))) }));

    processedData = processedData.filter((set: UserFilmSetType) =>
      set.entries.length > 0);

    if (reverseSort){
      processedData = reverseSort
        ?  processedData.map((set : UserFilmSetType)=>
          ({ label: set.label,
            entries: set.entries.reverse() }))
        : processedData;

      processedData.reverse();
    }

    let randomEntrySet : UserFilmSetType[]|undefined = undefined;

    if(random !== undefined){
      randomEntrySet = [
        { label: "Lucky Hit",
          entries: [unprocessedFlat[random]] }
      ];
    }

    return randomEntrySet ?? processedData;
  }, [filter, filmData, short, reverseSort, random]);

  const entryCount = processedGameData.reduce((accumulator: number, set)=>
    set.entries.length + accumulator,  0 );

  const completedCount = processedGameData.reduce((accumulator: number, set)=>
    set.entries.filter(entry => 
      entry.filmData.watchthroughs.some(playthrough=>
        playthrough.completed)).length + accumulator,  0 );

  const allEntriesCount = processedGameData.reduce((accumulatorMain: number, set)=>
    set.entries.reduce((accumulatorSub: number, entry) =>
      entry.filmData.watchthroughs.length + accumulatorSub, 0) + accumulatorMain,  0 );

  useEffect(() => {
    Modal.setAppElement("body");
    document.body.style.overflow = modalData
      ? "hidden"
      : "unset";
  }, [modalData]);

  const fetchFilmData = () => {
    setRandom(undefined);
    setFilmData([]);
    fetch(`${hostAddress}${userFilmsEndpoint}?sort=${sort}&labeled=${labeled}&mode=${mode}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        const preFilteredSets = data.map((set : UserFilmSetType)=>
          ({ label: set.label,
            entries: set.entries.filter((entry: UserFilmSetEntryType)=>
              entry.filmData.watchthroughs.length > 0) }));

        setFilmData(preFilteredSets);
      });
  };

  const onToggleSortDirection = () => {
    serReverseSort(!reverseSort);
  };

  const infoText = "Wyświetlanie kart w przypadku wielu seansów jednego filmu:\n\n" +
      "najnowsza - brak duplikatów kart, tylko najświeższy seans\n\n" +
      "najstarsza - brak duplikatów kart, tylko najstarszy seans\n\n" +
      "wszystkie - zduplikowane karty, wszystkie seanse";

  useEffect(() => {
    fetchFilmData();
  }, [variant]);

  return (
    <div className={"container-fluid mt-2"}>
      {filmData.length === 0 ?
        <div className={"d-flex justify-content-center"}>
          <CommonLoading/>
        </div>
        : <>
          <FilterPanel
            cardFilterInfoText={infoText}
            coverAttack={coverAttack}
            setCoverAttack={setCoverAttack}
            setRandom={setRandom}
            gameCount={unprocessedFlat.length}
            reverseSort={reverseSort}
            fetchData={fetchFilmData}
            onToggleSortDirection={onToggleSortDirection}
            setFilter={setFilter}/>

          <div>
            <div className={"d-flex justify-content-center pb-2 gap-2"}>
              <span className={"rounded-2 p-2 ps-3 pe-3 bg-primary"}>
                {`Filmy w dzienniku: ${entryCount}`}
              </span>
              <span className={"rounded-2 p-2 ps-3 pe-3 bg-primary"}>
                {`Wpisy w dzienniku: ${allEntriesCount}`}
              </span>
              <span className={"rounded-2 p-2 ps-3 pe-3 bg-primary"}>
                {`Obejrzanych: ${completedCount}`}
              </span>
            </div>
            {(processedGameData.length > 0 ?
              processedGameData.map((set) =>
                <UserFilmSet
                  coverAttack={coverAttack}
                  setModalData={setModalData}
                  key={`set-${set.label}`}
                  filmSet={set}/>
              )
              : <h4 className={"text-center mt-3"}>Where filmu :(</h4>
            )}
          </div>

        </> }

      {modalData &&
      <UserFilmDetails
        film={modalData as UserFilmType}
        setModalData={setModalData}/>
      }
    </div>
  );
};
