const TodoList = () => {
  const tasks = [
    "rozpracować loadingi okładek",
    "liczniki jakieś proste - gry usera, gry wszystkie",
    "paginacja :(((",
  ];

  const games = [
    "Ace ventura",
    "Chłopaki nie płaczą",
    "Mumia?",
    "Silkroad",
    "Remember me",
    "Ogame",
    "MyFantasy",
    "Bitefight",
    "Killzone liberation"
  ];

  return (
    <div className={"m-3"}>
      <ul>
        {tasks.map((task, index)=>
          <li key={`task-${index}`}>{task}</li>)}
      </ul>
      <br/>
      <ul>
        {games.map((task, index)=>
          <li key={`task-${index}`}>{task}</li>)}
      </ul>
    </div>
  );
};

export default TodoList;