import React, { FC } from "react";
import { Button } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
const NavigationIcon: FC<{small?: boolean, to: string, icon: string, className?: string}>
    = ({ to, icon, className, small }) => {
      const location = useLocation();

      return (
        <Button
          size={small
            ? "sm"
            : undefined}>
          <Link
            className={`nav-link p-3 ${className}`}
            style={{ cursor: "pointer" }}
            to={to}>
            <i
              className={`${location.pathname === to 
                ? "text-white"
                : "text-white-50"} fa-solid fa-${icon}`}></i>
          </Link>
        </Button>
      ); 
    };
export default NavigationIcon;