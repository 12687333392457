import React, { FC } from "react";

import {
  gameCoverEndpoint,
  hostAddress,
} from "../utils/constants";

const CardBackground : FC<{id: number,  visible: boolean, zIndex: number, endpoint: string}> =
    ({ id , zIndex, visible, endpoint }) => {
      return (
        <div
          className={`position-absolute overflow-hidden blurred-background h-100 w-100 ${visible
            ? "" 
            : "d-none"}`}
          style={{
            backgroundSize: "cover",
            backgroundImage: `url(${hostAddress}${endpoint}${id})`,
            backgroundPosition: "bottom",
            backgroundRepeat: "no-repeat",
            zIndex: zIndex,
          }}/>
      );
    };

export default CardBackground;