import React, { FC } from "react";

import { PlaythroughType } from "../utils/types";
import CustomTooltip from "./utils/custom-tooltip";

export const UserGameBadges : FC<{favourite: boolean, platinum: boolean,
    allPlaythroughs: PlaythroughType[], achieved: boolean, favouriteMusic: boolean }> =
    ({   platinum, favourite, allPlaythroughs, favouriteMusic, achieved }) => {

      const completed = allPlaythroughs.filter((playthrough)=>
        playthrough.completed).length > 0;

      const childGame = allPlaythroughs.filter((playthrough)=>
        playthrough.type === "kid").length > 0;

      return (
        <div className={"d-flex"}>
          <div className={"d-flex align-items-center gap-2"}>
            {favouriteMusic && <CustomTooltip text={"ulubiona muzyka"}>
              <span
                style={{ color: "#a4a5ff",
                  marginRight: 3 }}
                className={"fas fa-music"}></span>
            </CustomTooltip>}
            {achieved && <CustomTooltip text={"wszystkie osiągnięcia"}>
              <span className={"fas fa-trophy text-warning"}></span>
            </CustomTooltip>}
            {platinum && <CustomTooltip text={"platynka"}>
              <span
                style={{ color: "#36ffff" }}
                className={"fas fa-trophy"}></span>
            </CustomTooltip>}
            {favourite && <CustomTooltip text={"ulubiona"}>
              <span className={"fas fa-heart text-danger"}></span>
            </CustomTooltip>}
            {childGame && <CustomTooltip text={"gra dzieciństwa"}>
              <span
                style={{ color: "#63ff99" }}
                className={"fas fa-baby-carriage"}></span>
            </CustomTooltip>}
            {completed &&  <CustomTooltip text={"ukończona"}>
              <span className={"fas fa-circle-check"}></span>
            </CustomTooltip>}
          </div>
        </div>
      );
    };
