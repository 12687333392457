import React from "react";
import ReactLoading from "react-loading";

import VariantContext from "../../layout/context/variantContext";
import { colors } from "../../utils/colors";
import { loadingHeight, loadingType } from "../../utils/constants";

const CommonLoading = () => {
  const { variant } = React.useContext(VariantContext);

  return (
    <ReactLoading
      className={"m-3"}
      type={loadingType}
      color={colors[variant.theme as keyof typeof colors].loading}
      height={loadingHeight}/>
  );
};

export default CommonLoading;