import React, { FC } from "react";

const GameOverlay : FC<{ color: string, zIndex: number, opacity: number, visible: boolean}>
    = ({  color, zIndex, opacity, visible }) => {

      const colorOverride = "#ffffff";
      const opacityOverride = 0.29;
      return (
        <div
          className={`position-absolute w-100 h-100 ${visible
            ? ""
            : "d-none"}`}
          style={{
            backgroundSize: "cover",
            background: color,
            opacity: opacity,
            zIndex: zIndex
          }}>
        </div>
      );
    };

export default GameOverlay;