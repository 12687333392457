import "./user-review-badge.scss";

import React, { FC } from "react";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  badScoreThreshold,
} from "../utils/constants";
import { ReviewType } from "../utils/types";

const ReviewBadge : FC<{review?: ReviewType, reviewCount: number,
    showDate?: boolean, showCount?: boolean, active?: boolean, hoverable?: boolean,
  setActiveReview?: (id?: number) => void, index?: number} > = ({ review, reviewCount
    , showDate, index, showCount, active, setActiveReview }) => {

    const { variant } = React.useContext(VariantContext);
    const score = review?.score ?? null;

    const red1 = "#BC1F26";
    const red2 = "#f1774a";
    const yellow1 = "#FECC09";
    const green1 = "#0F9246";
    const green2 = "#7FBA39";
    const green5 = "#6BBF59";
    const green4 = "#0B6E4F";
    const green3 = "#01d38c";
    const blue2 = "#0099b2";
    const blue1 = "#242fb7";
    const purple1 = "#714AE0";
    const pink1 = "#D13BE0";

    const reverseFontColor = [yellow1, green3, green2, green5];

    const isPerfect = score === 100 && !review?.reminiscence && !review?.toned;

    const getProgressVariant = (score: number) => {
      if (score <= badScoreThreshold) {
        return red1;
      } else if (score <= 65) {
        return red2;
      } else if (score <= 70) {
        return yellow1;
      } else if (score <= 75) {
        return green5; //0ea472 01d38c 7FBA39 242fb7 0F9246
      } else if (score <= 80) {
        return green4; //0ea472 01d38c 7FBA39 242fb7 0F9246
      } else if (score <= 85) {
        return blue2; //0ea472 01d38c 7FBA39 242fb7 0F9246
      } else if (score <= 90) {
        return blue1;
      }
      else if (score < 100){
        return purple1;
      }
      else{
        return pink1;
      }
    };

    const getFontVariant = (progressVariant : string) => {
      return reverseFontColor.includes(progressVariant)
        ? "black"
        : "white";
    };

    const reviewLabel = (reviewCount > 5 || reviewCount === 0)
      ? "opinii"
      : (reviewCount === 1
        ? "opinia"
        : "opinie" );

    let progressVariant = getProgressVariant(score ?? 0);
    progressVariant = (!score || review?.reminiscence)
      ? "grey"
      : progressVariant;

    progressVariant = review?.toned
      ? "black"
      : progressVariant;

    const fontVariant = getFontVariant(progressVariant);

    return (
      <div
        onClick={() =>
          setActiveReview && setActiveReview(index)}
        style={{ height: 50,
          cursor: "pointer" }}
        className={"m-2 d-flex align-items-center ps-0 pe-0 rounded-3"}>
        <div
          style={{ width: 50,
            height: 50,
            backgroundColor: progressVariant }}
          className={`p-2 d-flex fs-${score 
            ? "5 fw-bold"
            : "7"} justify-content-center align-items-center rounded-3 ${isPerfect && "animated-border"}
            rounded-end-0 text-${!score || review?.reminiscence
        ? "black"
        : fontVariant}`}>
          <span className={"text-center"}>
            {(!score || score === 0)
              ? "brak oceny"
              : score}
          </span>
        </div>


        {showCount &&           <div
          style={{ backgroundColor: `${colors[variant.theme as keyof typeof colors].panelBg}95` }}
          className={"d-flex align-items-center gap-1 p-2 rounded-3 rounded-start-0 h-100 fs-7"}>
          <span className={"fs-6 fas fa-book me-1"}></span>
          <span>
            {`${reviewCount !== 0
              ? reviewCount
              : "brak"} ${reviewLabel}`}
          </span>
        </div>}

        {showDate &&  <div
          style={{ height: 50 }}
          className={`d-flex flex-column rounded-2 rounded-start-0 bg-primary text-center ms-0 p-2 fs-7 
          ${active && "bg-opacity-25 bg-white"}`}>
          <span className={"border-bottom border-1"}>
            {`${review?.date ?? "brak daty"}`}
          </span>
          <span>
            {`${review?.reminiscence
              ? "wspominka"
              : "opinia"}`}
          </span>
        </div>}
      </div>);
  };

export default ReviewBadge;