import React, { FC } from "react";

import {
  gameCoverEndpoint, gameCoverRatio,
  libraryGameContainerWidth, libraryGameCoverWidth
} from "../utils/constants";
import { GameType } from "../utils/types";
import { Cover } from "./cover";
import UserGameHeader from "./user-game-header";

const LibraryGame:
    FC<{game: GameType, onListView?: boolean }> =
    ({ game, onListView  }) => {

      return (
        <div
          style={{ width: libraryGameContainerWidth }}
          className={`position-relative overflow-hidden list-card m-${onListView
            ? "0"
            : "2"} bg-secondary`}>
          <div className={"d-flex"}>
            <Cover
              ratio={gameCoverRatio}
              endpoint={gameCoverEndpoint}
              width={libraryGameCoverWidth}
              id={game.id}/>
            <UserGameHeader
              hideLastHr
              game={game}/>
          </div>
        </div>
      );
    };

export default LibraryGame;