import React, { FC } from "react";

import {
  minUserGameListItemWidth
} from "../utils/constants";
import { FilmType, GameType, ListItemType, ListType, UserFilmType, UserGameType } from "../utils/types";
import { UserFilm } from "./user-film";
import UserGame from "./user-game";

export const UserCustomListItem : FC<{list: ListType, fixedWidth: boolean,
    isMobile: boolean, listItem: ListItemType ,
    game?: GameType , userGame?: UserGameType, film?: FilmType , userFilm?: UserFilmType}> =
    ({ game , isMobile, userGame, fixedWidth,
      film, userFilm,list, listItem }) => {
      const { showPriority, showDate, showValue, showUserDetails } = list;

      const { header, value, description, priority, date } = listItem;

      const valuesColors = [
        "text-info",
        "text-warning",
        "text-danger"
      ];

      const finalWidth = fixedWidth
        ? minUserGameListItemWidth
        : undefined;

      return (
        <div
          className={"d-flex flex-column h-100 custom-list-card w-100"}
          style={{ width: finalWidth,
            minWidth: finalWidth }}>
          <div
            className={"bg-primary rounded-top-3 d-flex p-3 w-100"}
          >
            <div className={"d-flex w-100 flex-wrap justify-content-between gap-4"}>
              {showPriority && <div className={"fs-4 d-flex gap-2 align-items-center"}>
                <span className={"fa-solid fa-trophy"}/>
                <span>{`# ${priority}`}</span>
              </div>}
              {showValue &&
              <div className={"d-flex gap-2 justify-content-center align-items-center"}>
                <span>Ranga: </span>
                {value > 0 && <i className={`fa-solid fs-5 fa-fire ${valuesColors[value - 1]}`}></i> }
                {value > 1 && <i className={`fa-solid fs-5 fa-fire ${valuesColors[value - 1]}`}></i> }
                {value > 2 && <i className={`fa-solid fs-5 fa-fire ${valuesColors[value - 1]}`}></i> }
              </div>}

              {showDate && <div><span>{`Dodano: ${date}`}</span></div>}
            </div>
          </div>


          {game &&
          <UserGame
            hoverable={false}
            fixedWidth={finalWidth}
            coverBackground
            onListView
            roundedBottom = {!description && !header}
            isMobile={isMobile}
            userGame={showUserDetails
              ? undefined
              : userGame}
            gameData={game}/>}

          {film &&
            <UserFilm
              hoverable={false}
              fixedWidth={finalWidth}
              roundedBottom = {!description && !header}
              coverBackground
              onListView
              isMobile={isMobile}
              userFilm={showUserDetails
                ? undefined
                : userFilm}
              filmData={film}/>}

          {(header || description) &&
            <div
              className={"d-flex flex-column gap-2 bg-primary rounded-bottom-3 p-3 text-wrap"}
              style=
                {{ maxWidth: finalWidth }}>
              {header && <span className={"fs-5 fw-bold"}>{header}</span>}
              {header && description && <hr className={"p-0 m-0"}/>}
              {description &&
              <span
                className={"fs-6"}>
                {description}
              </span>}
            </div>}
        </div>);
    };