import React, { FC, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import {
  bigScreenBreakpoint,
  filmCoverEndpoint, filmCoverRatio,
  gameCoverEndpoint,
  gameCoverRatio,
  userGameContainerWidth
} from "../utils/constants";
import { UserFilmSetType, UserFilmType, UserGameType } from "../utils/types";
import { Cover } from "./cover";
import { UserFilm } from "./user-film";
import { UserSetHeader } from "./user-set-header";

export const UserFilmSet: FC<{filmSet: UserFilmSetType,
    setModalData: (filmData: UserFilmType) => void, coverAttack: boolean}> =
    ({ filmSet,  setModalData, coverAttack }) => {
      const { variant } = React.useContext(VariantContext);
      const { advancedBgs, labeled } = variant;
      const { label, entries } = filmSet;

      const [setExtended, setSetExtended] = useState(true);

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
 
      return (
        <div>
          {entries.length > 0 && (labeled ?
            <UserSetHeader
              count={entries.length}
              setExtended={setExtended}
              toggleSetExtended={setSetExtended}
              label={label}
              theme={variant.theme}/>
            :
            "")}

          {setExtended &&
            <div
              className='
            mt-2
         d-flex
         align-items-center
         flex-wrap
         justify-content-center'>
              {entries.map((entry, index)=>
                (
                  <div
                    className={`${isBigScreen || coverAttack
                      ? ""
                      : "w-100"}`}
                    onClick={
                      () =>
                        setModalData(entry.filmData)
                    }
                    key={`entry-${entry.watchthroughData.id}`}>
                    {!coverAttack ?
                      <UserFilm
                        hoverable={isBigScreen}
                        filmData={entry.filmData.film}
                        isMobile={!isBigScreen}
                        fixedWidth={isBigScreen
                          ? userGameContainerWidth
                          : undefined}
                        userFilm={entry.filmData}
                        coverBackground={advancedBgs}
                        baseWatchthrough={entry.watchthroughData}/> :

                      <div className={"p-2"}>
                        <Cover
                          endpoint={filmCoverEndpoint}
                          id={entry.filmData.film.id}
                          width={100}
                          ratio={filmCoverRatio}/>
                      </div>
                    }
                  </div>

                ))}
            </div>}
        </div>
      );
    };