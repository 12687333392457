import React, { FC } from "react";

const ToggleAction: FC<{icon: string, value: boolean,
  action: (value: boolean) => void, alwaysActive?: boolean, hidden?: boolean, className?: string}> =
    ({ value, action, icon, alwaysActive, hidden, className }) => {
      return (
        <span
          className={`${className} ${hidden
            ? "d-none"
            : ""}`}
          style={{ cursor: "pointer" }}
          onClick={()=> 
            action(!value)
          }>
          <i className={`me-1 ms-1 fa-solid fa-${icon} ${(!value && !alwaysActive) && "opacity-50"}`}/>
        </span>
      );
    };

export default ToggleAction;