import classNames from "classnames";
import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";

import useDetailsHistory from "../hooks/use-details-history";
import {
  bigScreenBreakpoint,
  CustomListSort, hostAddress, userListEndpoint
} from "../utils/constants";
import { RoutePaths } from "../utils/routes";
import { DetailsModalType, ListItemType, ListType, UserFilmType, UserGameType } from "../utils/types";
import { UserCustomListFilters } from "./user-custom-list-filters";
import { UserCustomListItem } from "./user-custom-list-item";
import { UserFilmDetails } from "./user-film-details";
import UserGameDetails from "./user-game-details";
import { GradientLine } from "./utils/gradient-line";

export const UserCustomList = () => {

  const { id } = useParams();

  const fetchListsData = useCallback(() => {
    fetch(`${hostAddress}${userListEndpoint}${id}`)
      .then(response => {
        return response.json();
      })
      .then(data => {
        setListData(data);
      });
  }, []);

  useEffect(() => {
    fetchListsData();
  }, []);

  const navigate = useNavigate();

  const [items, setItems ]
      = useState<ListItemType[]>([]);

  const [listData, setListData ]
      = useState<ListType|undefined>(undefined);

  const [sort, setSort ]
        = useState(CustomListSort.EntryDate);

  const [filter, setFilter ]
      = useState<string>();

  const [reverseSort, setReverseSort ]
        = useState(true);

  const [gameModalData, setGameModalData]
      = useState<DetailsModalType|null>(null);

  const [filmModalData, setFilmModalData]
      = useState<DetailsModalType|null>(null);

  useDetailsHistory(gameModalData, setGameModalData, filmModalData, setFilmModalData);

  const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
  const fixedWidth = !(isMobile || listData?.isVertical);

  useEffect(() => {
    let defaultSort = CustomListSort.EntryDate;
    if(listData?.showPriority){
      defaultSort = CustomListSort.Priority;
    }else if(listData?.showValue){
      defaultSort = CustomListSort.Value;
    }
    setSort(defaultSort);
  }, [listData]);

  useEffect(()=>{
    const items = [...(listData?.items ?? []) ];
    const itemsFiltered = items.filter(item =>
      item.game?.name.toLowerCase()
        .includes(filter?.toLowerCase() ?? "") ||
          item.film?.titlesJoined.toLowerCase()
            .includes(filter?.toLowerCase() ?? ""));

    if(sort !== CustomListSort.EntryDate){
      itemsFiltered.sort((item, item2) : number => {

        if(sort === CustomListSort.ReleaseDate){
          const firstDate =
                Date.parse(reformatDate(item?.game?.released ?? item?.film?.yearDate ?? ""));
          const secondDate =
                Date.parse(reformatDate(item2?.game?.released ?? item2?.film?.yearDate ?? ""));

          return firstDate - secondDate;
        }else if (sort === CustomListSort.Name){
          const item1title = item?.game?.name ?? item?.film?.title ?? "";
          const item2title = item2?.game?.name ?? item2?.film?.title ?? "";
          return item1title.localeCompare(item2title);
        }
        else if (sort === CustomListSort.Value){
          return item?.value - item2?.value;
        }
        else if (sort === CustomListSort.Priority){
          return item2?.priority - item?.priority;
        }
        else{
          return 1;
        }
      });
    }

    if(reverseSort){
      itemsFiltered.reverse();
    }

    setItems(itemsFiltered);
  }, [listData, filter, sort, reverseSort]);

  const reformatDate = (date: string) => {
    const parts = date.split("-");
    return `${parts[2]}-${parts[1]}-${parts[0]}`;
  };

  return (
    <div
      className={`d-flex flex-column align-items-center mt-3 container${listData?.isVertical
        ? ""
        : "-fluid"}`}>
      <div className={"d-flex flex-wrap justify-content-between align-items-center w-100 container"}>

        <div>
          <div className={"fs-3"}>{listData?.name}</div>
          <div>{listData?.description}</div>
        </div>
        <div>
          <span
            onClick={() => 
              navigate(`${RoutePaths.LISTS_ROUTE}`)}
            className={"btn btn-danger"}>
            <i
              className={"me-2 fa-solid fa-arrow-rotate-left"}
            />
            <span>Powrót do list</span>
          </span>
        </div>
      </div>


      <div className={"d-flex w-100"}>
        <GradientLine reverse/>
        <GradientLine/>
      </div>


      {listData &&
      <>
        <UserCustomListFilters
          isMobile={isMobile}
          listData={listData}
          setItems={setItems}/>
        <div
          className={`d-flex gap-5 w-100 ${!listData.isVertical
            ? "flex-wrap align-items-stretch"
            : "flex-column"} justify-content-center align-items-center`}>
          {items.map((item) =>
            (
              <div
                className={
                  classNames("d-flex",
                    { "w-50": !fixedWidth && !isMobile },
                    { "w-100 m-2": !fixedWidth && isMobile })}

                key={`list-item-${item.id}`}
                onClick={() => {
                  setGameModalData(item?.userGame ?? null);
                  setFilmModalData(item?.userFilm ?? null);
                }}>

                <UserCustomListItem
                  fixedWidth={fixedWidth}
                  isMobile={isMobile}
                  list={listData}
                  listItem={item}
                  film={item.film}
                  userFilm={item.userFilm}
                  userGame={item.userGame}
                  game={item.game}/>

              </div>

            ))}
        </div>
      </>
      }

      {gameModalData &&
      <UserGameDetails
        game={gameModalData as UserGameType}
        setModalData={setGameModalData}/>
      }

      {filmModalData &&
      <UserFilmDetails
        film={filmModalData as UserFilmType}
        setModalData={setFilmModalData}/>
      }
    </div>
  );
};
