import React, { FC } from "react";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import {
  bigScreenBreakpoint,
  LabeledOptions,
  LabeledOptionsLabels,
  ModeNames,
  Modes,
  Sort,
  SortNames
} from "../utils/constants";
import CustomTooltip from "./utils/custom-tooltip";

export const FilterPanel : FC<{fetchData: () => void, reverseSort: boolean, onToggleSortDirection: () => void,
    setFilter: (filter: string) => void, setRandom: (randomID: number|undefined) => void, gameCount: number,
    setCoverAttack: (coverAttack: boolean) => void, coverAttack: boolean, cardFilterInfoText: string}> =
    ({ fetchData, setFilter, reverseSort, onToggleSortDirection, setRandom, cardFilterInfoText,
      gameCount, setCoverAttack, coverAttack }) => {

      const { variant, setVariant } = React.useContext(VariantContext);
      const { mode, labeled, sort } = variant;
      const onChangeLabeled = (value: string) => {
        setVariant({ ...variant,
          labeled: value === LabeledOptions.Labeled });
      };

      const onChangeSort = (value: string) => {
        setVariant({ ...variant,
          sort: value as Sort });
      };
      const onChangeMode = (value: string) => {
        setVariant({ ...variant,
          mode: value as Modes });
      };

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });
      const inputHeight = isBigScreen
        ? 50
        : 40;


      return (
        <div className={"d-flex justify-content-center gap-4 mt-3 mb-4 p-2 flex-wrap"}>
          <div
            className={`d-flex ${!isBigScreen && "w-100"}`}
            style={{ height: inputHeight }}>
            <input
              disabled
              value={"Szukaj"}
              type={"text"}
              style={{ maxWidth: 90 }}
              className={`rounded-end-0 w-auto text-center bg-primary
              border-0 text-body form-control`}/>
            <input
              onChange=
                {(e) =>
                  setFilter(e.target.value)}
              type={"text"}
              className={`w-${isBigScreen
                ? "auto"
                : "100"} text-center bg-secondary 
          border-0 rounded-start-0 text-body form-control ${isBigScreen && "form-control-lg"}`}/>
          </div>

          <div
            className={`d-flex bg-primary align-items-center rounded-2 ${!isBigScreen && "w-100"}`}
            style={{ height: inputHeight }}>
            <input
              disabled
              value={"Karty"}
              type={"text"}
              style={{ maxWidth: 55 }}
              className={`text-center bg-primary me-0 pe-0
              border-0 text-body form-control`}/>
            <CustomTooltip
              tooltipPlacemanet={"bottom"}
              text={cardFilterInfoText}>
              <span
                className={"fas fa-info-circle ms-1 me-3"}>
              </span>
            </CustomTooltip>
            <select
              value={mode}
              onChange=
                {(e) =>
                  onChangeMode(e.target.value as Modes)}
              className={`w-${isBigScreen
                ? "auto"
                : "100"} rounded-start-0 bg-secondary h-100 border-0 text-body form-select`}>
              {Object.keys(Modes).map((key) =>
                (
                  <option
                    key={`mode-${key}-option`}
                    value={Modes[key as keyof typeof Modes]}>
                    {ModeNames[Modes[key as keyof typeof Modes] as keyof typeof ModeNames]}
                  </option>
                ))}
            </select>
          </div>

          <div
            className={`d-flex ${!isBigScreen && "w-100"}`}
            style={{ height: inputHeight }}>
            <input
              disabled
              value={"Sortuj"}
              type={"text"}
              style={{ maxWidth: 90 }}
              className={`rounded-end-0 w-auto text-center bg-primary
              border-0 text-body form-control form-control-lg"`}/>
            <select
              value={sort}
              onChange=
                {(e) =>
                  onChangeSort(e.target.value)}
              className={`w-${isBigScreen
                ? "auto"
                : "100"} rounded-0 bg-secondary border-0 text-body form-select`}>
              {Object.keys(Sort).map((key) =>
                (
                  <option
                    key={`sort-${key}-option`}
                    value={Sort[key as keyof typeof Sort]}>
                    {SortNames[Sort[key as keyof typeof Sort] as keyof typeof SortNames]}
                  </option>
                ))}
            </select>

            <div
              onClick={onToggleSortDirection}
              className={"btn rounded-start-0 btn-secondary d-flex align-items-center"}
              style={{ height: inputHeight }}>
              <i
                className={`fa-solid fa-${reverseSort
                  ? "arrow-up-wide-short"
                  : "arrow-down-wide-short"}`}></i>
            </div>
          </div>

          <div
            className={`d-flex ${!isBigScreen && "w-100"}`}
            style={{ height: inputHeight }}>
            <input
              disabled
              value={"Etykiety"}
              type={"text"}
              style={{ maxWidth: 90 }}
              className={`rounded-end-0 w-auto text-center bg-primary
              border-0 text-body form-control form-control-lg"`}/>

            <select
              value={labeled
                ? LabeledOptions.Labeled
                : LabeledOptions.Unlabeled}
              onChange=
                {(e) =>
                  onChangeLabeled(e.target.value)}
              className={`w-${isBigScreen
                ? "auto"
                : "100"} rounded-start-0 bg-secondary border-0 text-body form-select`}>
              {Object.keys(LabeledOptions).map((key) =>
                (
                  <option
                    key={`labeled-${key}-option`}
                    value={LabeledOptions[key as keyof typeof LabeledOptions]}>
                    {LabeledOptionsLabels[
                          LabeledOptions[key as keyof typeof LabeledOptions] as keyof typeof LabeledOptionsLabels]}
                  </option>
                ))}
            </select>
          </div>

          <div className={"d-flex"}>
            <div
              style={{ height: inputHeight }}
              onClick={() =>
                setRandom(Math.floor(Math.random() * gameCount))}
              className={"ps-3 pe-3 d-flex align-items-center btn btn-secondary rounded-end-0"}>
              <i
                className={"fa-solid fa-shuffle"}
              />
            </div>

            <div
              style={{ height: inputHeight }}
              onClick={() =>
                setRandom(undefined)}
              className={"d-flex pe-3 ps-3 align-items-center btn btn-secondary rounded-start-0"}>
              <i
                className={"fa-solid fa-xmark"}
              />
            </div>
          </div>

          <div
            style={{ height: inputHeight }}
            onClick={() =>
              setCoverAttack(!coverAttack)}
            className={"ps-3 d-flex pe-3 align-items-center btn btn-secondary"}>
            <i
              className={`fa-${coverAttack
                ? "solid"
                : "regular"} fa-image`}
            />
          </div>

          <div
            style={{ height: inputHeight }}
            onClick={fetchData}
            className={"ps-3 d-flex pe-3 align-items-center btn btn-secondary"}>
            <i
              className={"fa-solid fa-arrows-rotate"}
            />
          </div>


        </div>);
    };