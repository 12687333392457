import { FastAverageColor } from "fast-average-color";
import fontColorContrast from "font-color-contrast";
import React, { FC, useEffect, useState } from "react";
import Modal from "react-modal";
import { useMediaQuery } from "react-responsive";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import {
  bigScreenBreakpoint,
  bigScreenDetailsBreakpoint,
  filmCoverEndpoint,
  filmCoverRatio,
  hostAddress, userGameCoverWidthSmall,
  userGameDetailsContainerWidth,
  userGameDetailsCoverWidth, userGameDetailsModalWidth, userGameMinDetailsDiaryWidth
} from "../utils/constants";
import { UserFilmType } from "../utils/types";
import CardBackground from "./card-background";
import { Cover } from "./cover";
import GameOverlay from "./game-overlay";
import ReviewBadge from "./review-badge";
import { UserFilmBadges } from "./user-film-badges";
import { UserFilmHeader } from "./user-film-header";
import { UserFilmWatchthrough } from "./user-film-watchthrough";
import UserReview from "./user-review";
import InteractiveIcon from "./utils/interactive-icon";

export const UserFilmDetails : FC<{film: UserFilmType, setModalData: (filmData: UserFilmType|null) => void}> =
    ({ film, setModalData }) => {
      const [activeReview, setActiveReview] = useState<number|undefined>(film.reviews.length > 0
        ? 0
        : undefined);

      const isBigScreen = useMediaQuery({ query: `(min-width: ${bigScreenDetailsBreakpoint}px)` });
      const isMobile = !useMediaQuery({ query: `(min-width: ${bigScreenBreakpoint}px)` });

      const { variant } = React.useContext(VariantContext);
      const [isLight, setIsLight] =useState<boolean>(false);

      const { favourite, childhood, watchthroughs, favouriteMusic } = film;
      const allWatchthroughs = watchthroughs ?? [];

      const assessFontColor = async () => {
        const fac = new FastAverageColor();
        const color = await fac.getColorAsync(`${hostAddress}${filmCoverEndpoint}${film.film.id}`);
        const fontColor = fontColorContrast(color.hex, 0.8);
        setIsLight(fontColor === "#000000");
      };

      useEffect(() => {
        assessFontColor();
      }, []);

      return (
        <Modal
          style={{
            overlay: {
              overflowY: "hidden",
              position: "fixed",
              zIndex: 105,
              backgroundColor: "rgba(0,0,0, 0.9)"
            },
            content: {
              margin: isMobile
                ? 0
                : undefined,
              minHeight: isMobile
                ? "100%"
                : undefined,
              maxHeight: isMobile
                ? "100%"
                : "90%",
              outline: "0",
              borderRadius: isMobile
                ? undefined
                : "6px",
            }
          }}
          onRequestClose={() =>
            setModalData(null)}
          className={`p-0 bg-primary position-absolute overflow-auto 
          ${!isMobile
          ? "scroll-secondary start-50 top-50 translate-middle"
          : "top-50 translate-middle-y hide-scrollbar"}`}
          isOpen={true}
          shouldCloseOnOverlayClick={true}
        >
          <div
            className={`d-flex gap-${isMobile
              ? 2
              : 4} mb-0 ${!isBigScreen && "flex-wrap"} position-relative overflow-hidden`}>
            <CardBackground
              endpoint={filmCoverEndpoint}
              visible={true}
              id = {film.film.id}
              zIndex={-10}/>
            <GameOverlay
              opacity={colors[variant.theme as keyof typeof colors].imageBgOverlayDetailsOpacity}
              color={colors[variant.theme as keyof typeof colors].bgOverlay}
              zIndex={-5}
              visible={true}/>
            {isBigScreen ?
              <InteractiveIcon
                className={"m-1 text-danger position-absolute top-0 end-0"}
                iconName={"fas fa-circle-xmark"}
                onClick={() =>
                  setModalData(null)}/> :

              <div
                onClick={() =>
                  setModalData(null)}
                className={"btn btn-danger ms-auto mt-3 me-3 mb-2 d-flex align-items-center rounded-0"}>
                <i
                  className={"me-2 fa-solid fa-arrow-rotate-left fs-6"}
                />
                <span className={"fs-6"}>Powrót</span>
              </div>}

            <div
              className={`d-flex ${!isBigScreen && "p-3 w-100 border-top border-1 border-primary"}`}
              style={{ minWidth: isBigScreen
                ? userGameDetailsContainerWidth
                : undefined,
              width: isBigScreen
                ? userGameDetailsContainerWidth
                : undefined }}>
              <Cover
                ratio={filmCoverRatio}
                endpoint={filmCoverEndpoint}
                id={film.film.id}
                width={isMobile
                  ? userGameCoverWidthSmall
                  : userGameDetailsCoverWidth}/>
              <UserFilmHeader
                isMobile={isMobile}
                lightCover={!isLight}
                stroke
                tags={film.tags}
                hideLastHr
                film={film.film}/>
            </div>
            <div className={"ps-3 pe-3 w-100"}>
              <div
                style={{ minWidth: !isMobile
                  ? userGameMinDetailsDiaryWidth
                  : undefined }}
                className={`${!isLight
                  ? "text-shadow-black"
                  : "text-black"} mb-2 text-center mt-2`}>
                Dziennik
              </div>
              <div>
                {film.watchthroughs.map((watchthrough) =>
                  <div
                    className={"mt-2 mb-2"}
                    key={`watchthrough-${watchthrough.id}`}>
                    <UserFilmWatchthrough
                      watchthrough={watchthrough}
                      count={film.watchthroughs.length}/>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className={"d-flex justify-content-between align-items-center mt-2 mb-2"}>

            <div className={"d-flex"}>
              {film.reviews.map((review, index)=>
                (
                  <ReviewBadge
                    index = {index}
                    setActiveReview = {setActiveReview}
                    key={review.id}
                    review={review}
                    reviewCount={film.reviews.length}
                    active={activeReview === index}
                    showDate/>
                ))}

              {film.reviews?.length === 0 &&
              <span className={"ms-2 p-2 rounded-2 bg-secondary"}>
                Brak opinii
              </span> }
            </div>
            <div className={"me-2 p-0"}>
              <UserFilmBadges
                favourite={favourite}
                childhood={childhood}
                favouriteMusic={favouriteMusic}
                allWatchthroughs={allWatchthroughs}/>
            </div>
          </div>

          {activeReview !== undefined && (film.reviews[activeReview].entries.length > 0) &&
          <div className={"bg-secondary m-2 mb-3 rounded-2 p-1"}>
            <UserReview review={film.reviews[activeReview]}/>
          </div>
          }
        </Modal>);
    };