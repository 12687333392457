import React, { FC } from "react";

export const GradientLine: FC<{reverse?: boolean}> = ({ reverse }) => {

  return (
    <hr
      className={`w-100 gradient-line-${reverse
        ? "right"
        : "left"}`}/>
  );
};