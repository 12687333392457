import React, { FC } from "react";

import { ReviewEntryType } from "../utils/types";

const UserReviewEntry : FC<{reviewEntry: ReviewEntryType, lastEntry?: boolean}>  =
    ({ reviewEntry, lastEntry }) => {
      const subentries = reviewEntry.text.split("<break>");
      const trimmedSubentries  = subentries.map((subentry => 
        subentry.trim()));

      return (

        <div
          key={`review-entry-${reviewEntry.id}`}>
          {reviewEntry.header && <div>
            <h5
              className={"mb-2 flex-shrink w-auto text-white"}>
              {reviewEntry.header}
            </h5>
          </div>}
          <div
            className={lastEntry
              ? "mb-0"
              : "mb-4"}>
            {trimmedSubentries.map((subentry, index) =>
              (
                <div
                  className={"d-flex align-items-start position-relative"}
                  key={`subentry-${index}`}>
                  <div
                    style={{ position: "absolute",
                      top: 3 }}>
                    {subentry.startsWith("[+]") && <span
                      style={{ width: 10 }}
                      className={"text-center fs-7 text-success fas fa-plus"}></span>}
                    {subentry.startsWith("[-]") && <span
                      style={{ width: 10 }}
                      className={"text-center fs-7 text-danger fas fa-minus"}></span>}
                    {subentry.startsWith("[?]") && <span
                      style={{ width: 10 }}
                      className={"text-center fs-7 fas fa-plus-minus text-warning"}>
                    </span>}
                  </div>

                  {subentry.startsWith("[+]") || subentry.startsWith("[-]") || subentry.startsWith("[?]")
                    ? <span className={"ms-3 ps-1 mt-1 mb-1"}>
                      {subentry.substring(3)}
                    </span>
                    :
                    <span>
                      {subentry}
                    </span>}
                  {index < (subentry.length - 1) && <br/>}
                </div>
              ))}
          </div>
        </div>);
    };

export default UserReviewEntry;