import { useCallback, useEffect } from "react";

import { DetailsModalType } from "../utils/types";

const useDetailsHistory = (firstModalData: DetailsModalType, setFirstModalData: (data: DetailsModalType) => void,
  secondModalData?: DetailsModalType, setSecondModalData?: (data: DetailsModalType) => void) => {
  const handlePopState = useCallback(
    () =>
    {
      if(firstModalData){
        setFirstModalData(null);
      }

      if(secondModalData && setSecondModalData){
        setSecondModalData(null);
      }
    }, [firstModalData, secondModalData]);

  useEffect(() => {
    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [handlePopState]);


  useEffect(() => {
    if((firstModalData || secondModalData) && !window.history?.state?.modalOpened){
      window.history.pushState({ modalOpened: true }, "");
    }

    if(!firstModalData && !secondModalData && window.history?.state?.modalOpened){
      window.history.back();
    }
  }, [firstModalData, secondModalData]);

};

export default useDetailsHistory;