import React, { FC } from "react";

import VariantContext from "../layout/context/variantContext";
import { colors } from "../utils/colors";
import { playthroughSlugMaxLength, playTypeIcons, playTypeTexts } from "../utils/constants";
import { PlaythroughType } from "../utils/types";
import CustomTooltip from "./utils/custom-tooltip";
import TooltipBadge from "./utils/tooltip-badge";

const UserGamePlaythrough : FC<{playthrough: PlaythroughType,
    playthroughCount: number, previewFlag?: boolean }> =
    ({ playthrough, playthroughCount, previewFlag }) => {

      const { variant } = React.useContext(VariantContext);

      const lastIndex = playthroughCount - 1;
      const multiplePlaythroughs = lastIndex > 0;

      const firstPlaythroughTooLong = previewFlag && playthrough.description.length > playthroughSlugMaxLength;

      return (
        <div
          style={{ backgroundColor: `${colors[variant.theme as keyof typeof colors].panelBg}` }}
          className={"playthroughs-container p-2 ps-3 pe-3 position-relative fs-7"}>
          {multiplePlaythroughs && previewFlag &&
          <span className={"position-absolute end-0 top-0 pt-1"}>
            <TooltipBadge
              className={"rounded-2 rounded-end-0 rounded-top-0 p-1 pe-2 ps-2"}
              text={`+${lastIndex}`}
              tooltipText={"liczba pozostałych wpisów"}/>
          </span>}

          <div
            className={"d-flex align-items-center mb-1 mt-1 justify-content-between"}>
            <div
              style={{ minWidth: 65 }}
              className=
                {"text-nowrap d-flex flex-column justify-content-center align-content-center"}>
              <span>{playthrough.dateFinished ?? "brak daty"}</span>
              <span>
                <span className={"pe-2"}>{playthrough.platform ?? ""}</span>
                <span
                  className={"pe-1 fa-solid fa-clock"}>
                </span>
                <span>
                  {`${playthrough.estTime
                    ? `${playthrough.estTime} h`
                    : "-" }`}
                </span>
              </span>
            </div>
            <div
              className={"flex-grow-1 ps-2 text-wrap text-break ms-4"}>
              <span>
                {firstPlaythroughTooLong
                  ? `${playthrough.description.substring(0, playthroughSlugMaxLength)} [...]`
                  : playthrough.description}
              </span>
            </div>
            <div className={"fs-7 text-nowrap d-none"}>
              <CustomTooltip
                text={playTypeTexts[playthrough.type as keyof typeof playTypeTexts]}>
                <span
                  style={{ minWidth: 30 }}
                  className=
                    {`ps-2 pe-1 fa-solid fs-6
                          fa-${playTypeIcons[playthrough.type as keyof typeof playTypeIcons]}`}/>
              </CustomTooltip>
              <CustomTooltip
                text={playthrough.completed
                  ? "ukończono"
                  : "nie ukończono lub nie dotyczy"}>
                <span
                  className={`ps-1 pe-1 fs-6 fa-${playthrough.completed
                    ? "solid"
                    : "regular"} fa-flag`}>
                </span>
              </CustomTooltip>
            </div>
          </div>
        </div>
      );
    };

export default UserGamePlaythrough;